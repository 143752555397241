import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import ScrollContainerVerticalForLeaderboard from "./ScrollContainerVerticalForLeaderboard";
import {championsHistoryMatchData, eplMatchData, turkeyHistoryMatchData} from "./config/firebase";

const LeaderboardPage = () => {

    const [matches1, setMatches1] = React.useState([] as any);
    const [matches2, setMatches2] = React.useState([] as any);
    const [matches3, setMatches3] = useState([]);

    const [dailyMatches, setDailyMatches] = React.useState([] as any);
    const [weeklyMatches, setWeeklyMatches] = React.useState([] as any);
    const [fullMatches, setFullMatches] = React.useState([] as any);

    const [dailyActive, setDailyActive] = React.useState(true);
    const [weeklyActive, setWeeklyActive] = React.useState(false);
    const [fullActive, setFullActive] = React.useState(false);

    const [matchData, setMatchData] = React.useState([]);

    const [classname1, setClassname1] = React.useState('categoryItems active');
    const [classname2, setClassname2] = React.useState('categoryItems');
    const [classname3, setClassname3] = useState('categoryItems');

    const [activeButton1, setActiveButton1] = React.useState("durationButton active")
    const [activeButton2, setActiveButton2] = React.useState("durationButton")
    const [activeButton3, setActiveButton3] = React.useState("durationButton")

    const [league1ImgUrl, setLeague1ImgUrl] = useState('');
    const [league2ImgUrl, setLeague2ImgUrl] = useState('');
    const [league3ImgUrl, setLeague3ImgUrl] = useState('');

    const [scrollHeight, setScrollHeight] = useState(200);

    const combinedMatches = async () => {

        turkeyHistoryMatchData().then(data => {
            //setLeague1ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
            setMatches1(data.DATA[0].EVENTS)
            setMatchData(data.DATA[0].EVENTS);
        })
        championsHistoryMatchData().then(data => {
            //setLeague2ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
            setMatches2(data.DATA[0].EVENTS);
        })
        eplMatchData().then(data => {
            //setLeague3ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
            setMatches3(data.DATA[0].EVENTS)
        })
    }

    useEffect(() => {
        setScrollHeight(window.innerHeight-540)
    }, []);

    useEffect(() => {
        combinedMatches();
    }, []);

    useEffect(() => {
        dailyFilter();
    }, [matchData,dailyActive]);

    useEffect(() => {
        weeklyFilter();
    }, [weeklyActive]);

    useEffect(() => {
        setFullMatches(matchData)
    }, [fullActive]);

    const dailyFilter = () => {

        const today = new Date();
        const dayOfToday = getDayNumber(today);

        const filtered:string[][] = [];

        matchData.forEach(item => {
            // @ts-ignore
            if (item.STAGE_TYPE === "FINISHED") {
                // @ts-ignore
                const date = new Date(item.START_TIME * 1000);
                const dayOfMatch = getDayNumber(date);
                if (dayOfToday  === dayOfMatch)
                    filtered.push(item);

            } else {
                filtered.push(item);
            }
        });
        setDailyMatches(filtered)
    }

    const weeklyFilter = () =>{
        const today = new Date();
        const weekOfToday = getWeekNumber(today);

        const filtered:string[][] = [];

        matchData.forEach(item => {
            // @ts-ignore
            if (item.STAGE_TYPE === "FINISHED") {
                // @ts-ignore
                const date = new Date(item.START_TIME * 1000);
                const weekOfMatch = getWeekNumber(date);
                if (weekOfToday  === weekOfMatch)
                    filtered.push(item);

            } else {
                filtered.push(item);
            }
        });
        setWeeklyMatches(filtered)
    }

    const handleActivate1 = () => {
        setMatchData(matches1)
        setClassname1("categoryItems active")
        setClassname2("categoryItems")
        setClassname3("categoryItems")
        resetTimeDuration()
    }
    const handleActivate2 = () => {
        setMatchData(matches2)
        setClassname1("categoryItems")
        setClassname2("categoryItems active")
        setClassname3("categoryItems")
        resetTimeDuration()
    }
    const handleActivate3 = () => {
        setMatchData(matches3)
        setClassname1("categoryItems")
        setClassname2("categoryItems")
        setClassname3("categoryItems active")
        resetTimeDuration()
    }

    const resetTimeDuration = () => {
        setActiveButton1("durationButton active")
        setActiveButton2("durationButton")
        setActiveButton3("durationButton")
        setDailyActive(true)
        setWeeklyActive(false)
        setFullActive(false)
    }

    const handleActivateDaily = () => {
        resetTimeDuration();

    }
    const handleActivateWeekly = () => {
        setActiveButton1("durationButton")
        setActiveButton2("durationButton active")
        setActiveButton3("durationButton")
        setDailyActive(false)
        setWeeklyActive(true)
        setFullActive(false)
    }
    const handleActivateTournament = () => {
        setActiveButton1("durationButton")
        setActiveButton2("durationButton")
        setActiveButton3("durationButton active")
        setDailyActive(false)
        setWeeklyActive(false)
        setFullActive(true)
    }

    const getDayNumber = (date:Date) => {
        const copiedDate = new Date(date.getTime());

        copiedDate.setDate(copiedDate.getDate() + 4 - (copiedDate.getDay() || 7));

        const yearStart = new Date(copiedDate.getFullYear(), 0, 1);

        const dayNumber = Math.ceil((((copiedDate.getTime() - yearStart.getTime()) / 86400000) + 1));

        return dayNumber;
    };
    const getWeekNumber = (date:Date) => {
        const copiedDate = new Date(date.getTime());

        copiedDate.setDate(copiedDate.getDate() + 4 - (copiedDate.getDay() || 7));

        const yearStart = new Date(copiedDate.getFullYear(), 0, 1);

        const weekNumber = Math.ceil((((copiedDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

        return weekNumber;
    };


    return (
        <div>
            <div style={{
                placeItems:"center"
            }}>
                <h1 className={"bigHeader"}>TOURNAMENT LEADERBOARD</h1>
                <text className={"subInfo"}>Make the correct predictions and compete with other players</text>
                <div>
                    <div className={"buttonContainer"}>
                        <button onClick={handleActivate1} className={classname1}>
                            <img src={require("./Images/tslIcon.png")} alt="team1Img" style={{
                                width: 24,
                                height: 24
                            }}></img>
                            TSL
                        </button>
                        <button onClick={handleActivate2} className={classname2}>
                            <img src={require("./Images/clIcon.png")} alt="team2Img" style={{
                                width: 24,
                                height: 24
                            }}></img> CL
                        </button>
                        <button onClick={handleActivate3} className={classname3}>
                            <img src={require("./Images/eplIcon.png")} alt="team3Img" style={{
                                width: 24,
                                height: 24
                            }}></img> EPL
                        </button>
                    </div>
                    <div style={{
                        marginTop: 8
                    }}>
                        <button onClick={handleActivateDaily} className={activeButton1}>DAILY</button>
                        <button onClick={handleActivateWeekly} className={activeButton2}>WEEKLY</button>
                        <button onClick={handleActivateTournament} className={activeButton3}>TOURNAMENT</button>
                    </div>

                    {dailyActive && <ScrollContainerVerticalForLeaderboard height={scrollHeight}
                                                                           items={dailyMatches}></ScrollContainerVerticalForLeaderboard>}
                    {weeklyActive && <ScrollContainerVerticalForLeaderboard height={scrollHeight}
                                                                            items={weeklyMatches}></ScrollContainerVerticalForLeaderboard>}
                    {fullActive && <ScrollContainerVerticalForLeaderboard height={scrollHeight}
                                                                          items={fullMatches}></ScrollContainerVerticalForLeaderboard>}
                    <div style={{
                        display: "grid",
                        textAlign: "left",
                        marginLeft: 16,
                        marginTop: 6
                    }}>
                        <h4>Reward</h4>
                        <text className={"subInfo"}>Which the winner will receive at the end of the tournament..</text>
                        <text className={"rewardText"}>Glory!</text>
                    </div>
                    <div style={{
                        textAlign: "left",
                        marginLeft: 16,
                        marginTop: 10,
                    }}>
                        <h4 style={{
                            color: "grey",
                            fontSize: 8
                        }}>*Correct score guess: 5 points</h4>
                        <h4 style={{
                            color: "grey",
                            fontSize: 8
                        }}>*Correct score difference: 2 points</h4>
                        <h4 style={{
                            color: "grey",
                            fontSize: 8
                        }}>*Correct winning side guess: 1 points</h4>
                    </div>
                    <NavLink to={"/leagues/joined"} className={"joinedLeagueButton"} style={{
                        marginBottom:5
                    }}>
                        <div>
                            <h4 className={"headerText"}>Leagues you joined</h4>
                            <text className={"subInfo"}>Prizes wil be received at the end of tournaments</text>
                        </div>
                        <div style={{
                            display: "grid",
                            width: "25vw",
                            justifyItems: "end",
                            alignItems: "center",
                        }}>
                            <img src={require("./Images/BackButton.png")} style={{
                                rotate: "180deg",
                                marginTop:-5
                            }}/>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>


    );
};

export default LeaderboardPage;
import React from 'react';
import LeaderboardPage from "./LeaderboardPage";

const LeaguesPage = () => {
    return (
        <div>
            <LeaderboardPage></LeaderboardPage>
        </div>
    );
};

export default LeaguesPage;
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getDatabase} from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDwd6oCgN3-ycZkz_NsyWGpQ8k32Tc3LSs",
    authDomain: "predictions-caac5.firebaseapp.com",
    databaseURL: "https://predictions-caac5-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "predictions-caac5",
    storageBucket: "predictions-caac5.appspot.com",
    messagingSenderId: "1092202250048",
    appId: "1:1092202250048:web:7bc4d6e4ef587fe83b44a5"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const firestore = getFirestore(app);


export const championsMatchData = async () =>{
    const url = 'https://flashlive-sports.p.rapidapi.com/v1/tournaments/fixtures?locale=en_GB&tournament_stage_id=pfpL1ZhB&tournament_season_id=x0KXIZfD';
    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': 'ab36d0a27emshd7b27907adcc0a1p1d9038jsn0effadc41868',
            'x-rapidapi-host': 'flashlive-sports.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}
export const championsHistoryMatchData = async () =>{
    const url = 'https://flashlive-sports.p.rapidapi.com/v1/tournaments/results?locale=en_GB&tournament_stage_id=pfpL1ZhB&tournament_season_id=x0KXIZfD';
    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': 'ab36d0a27emshd7b27907adcc0a1p1d9038jsn0effadc41868',
            'x-rapidapi-host': 'flashlive-sports.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}
export const eplMatchData = async () =>{
    const url = 'https://flashlive-sports.p.rapidapi.com/v1/tournaments/fixtures?locale=en_GB&tournament_stage_id=lAkHuyP3&tournament_season_id=xjLzNo5G';
    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': 'ab36d0a27emshd7b27907adcc0a1p1d9038jsn0effadc41868',
            'x-rapidapi-host': 'flashlive-sports.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}
export const eplHistoryMatchData = async () =>{
    const url = 'https://flashlive-sports.p.rapidapi.com/v1/tournaments/results?locale=en_GB&tournament_stage_id=lAkHuyP3&tournament_season_id=xjLzNo5G';
    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': 'ab36d0a27emshd7b27907adcc0a1p1d9038jsn0effadc41868',
            'x-rapidapi-host': 'flashlive-sports.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}
export const turkeyMatchData = async () =>{
    const url = 'https://flashlive-sports.p.rapidapi.com/v1/tournaments/fixtures?locale=en_GB&tournament_stage_id=jy5jZF2o&tournament_season_id=razDyylR';
    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': 'ab36d0a27emshd7b27907adcc0a1p1d9038jsn0effadc41868',
            'x-rapidapi-host': 'flashlive-sports.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}
export const turkeyHistoryMatchData = async () =>{
    const url = 'https://flashlive-sports.p.rapidapi.com/v1/tournaments/results?locale=en_GB&tournament_stage_id=jy5jZF2o&tournament_season_id=razDyylR';
    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': 'ab36d0a27emshd7b27907adcc0a1p1d9038jsn0effadc41868',
            'x-rapidapi-host': 'flashlive-sports.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}


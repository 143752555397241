import React, {useEffect, useRef, useState} from 'react';
import LeaderboardSlot from "./LeaderboardSlot";
import {collection, getDocs} from "firebase/firestore";
import {firestore} from "./config/firebase";
import PodiumSlot from "./PodiumSlot";
import Loading from "./Loading";

// @ts-ignore
interface ListProps  {
    height: number;
    items: string[][];
}

const ScrollContainerVerticalForLeaderboard: React.FC<ListProps>= ({height,items}) => {
    const scrollViewRef = useRef<HTMLDivElement>(null);
    const users = collection(firestore,"users");
    const [userData, setUserData] = React.useState([] as any);
    const [predictionData, setPredictionData] = React.useState([] as any);
    const [scores, setUserScores] = React.useState<string[][]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const getUserData = async () => {
        setIsLoading(true)
        try {
            const prediction = await getDocs(users);
            const simplified = prediction.docs.map((doc) => ({...doc.data(),id:doc.id}));
            setUserData(simplified)

        }
        catch (err){
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTop = 0;
        }

        getUserData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            let userD:string[][] = [];
            try {
                for (let i = 0; i < userData.length; i++) {
                    let user: string[] = [];
                    let username = "Guest";
                    if (userData[i].nickname !== undefined){
                        username = userData[i].nickname;
                    }
                    let correctGuesses = 0;
                    let points = 0;
                    let predictions = 0;
                    for (let j = 0; j < items.length; j++) {
                        // @ts-ignore
                        if (userData[i].predictions && Object.keys(userData[i].predictions).length>0){
                            // @ts-ignore
                            for (let k = 0; k < Object.keys(userData[i].predictions).length; k++) {
                                // @ts-ignore
                                if (Object.keys(userData[i].predictions)[k] === items[j].HOME_PARTICIPANT_NAME_ONE + items[j].AWAY_PARTICIPANT_NAME_ONE) {
                                    predictions++;
                                    // @ts-ignore
                                    if (Object.values(userData[i].predictions)[k].prediction1 === items[j].HOME_SCORE_CURRENT && Object.values(userData[i].predictions)[k].prediction2 === items[j].AWAY_SCORE_CURRENT){
                                        correctGuesses++;
                                        points+=5;
                                    }
                                    else{
                                        // @ts-ignore
                                        if ((Object.values(userData[i].predictions)[k].prediction1-Object.values(userData[i].predictions)[k].prediction2 === items[j].HOME_SCORE_CURRENT-items[j].AWAY_SCORE_CURRENT)){
                                            points+=2;
                                        }
                                        // @ts-ignore
                                        else if((Object.values(userData[i].predictions)[k].prediction1>Object.values(userData[i].predictions)[k].prediction2 &&items[j].HOME_SCORE_CURRENT>items[j].AWAY_SCORE_CURRENT) || (Object.values(userData[i].predictions)[k].prediction1<Object.values(userData[i].predictions)[k].prediction2 &&items[j].HOME_SCORE_CURRENT<items[j].AWAY_SCORE_CURRENT)|| (Object.values(userData[i].predictions)[k].prediction1===Object.values(userData[i].predictions)[k].prediction2 &&items[j].HOME_SCORE_CURRENT===items[j].AWAY_SCORE_CURRENT)){
                                            points+=1
                                        }
                                    }
                                    // @ts-ignore
                                    if (Object.values(userData[i].predictions)[k].doublePoint)
                                        points=points*2;
                                    // @ts-ignore
                                    if (Object.values(userData[i].predictions)[k].matchOfTheDay)
                                        points=points*2;
                                }
                            }
                        }
                    }
                    user = [username, predictions.toString(), correctGuesses.toString(), points.toString(),userData[i].id];
                    userD.push(user);
                }
                userD.sort((a, b) => parseInt(b[3]) - parseInt(a[3]));

                setUserScores(userD);

            } catch (err) {
                console.log(err);
            }

        };

        fetchData();
    }, [items, predictionData, userData]);

        return (
            <>
            {isLoading ? (<Loading />) : (<div>
                <div className={"podiumContainer"}>
                    <PodiumSlot length={48} placement={2} userD={scores[1]}></PodiumSlot>
                    <PodiumSlot length={72} placement={1} userD={scores[0]}></PodiumSlot>
                    <PodiumSlot length={48} placement={3} userD={scores[2]}></PodiumSlot>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10
                }}>
                    <text style={{
                        color: "darkgrey"
                    }}>#
                    </text>
                    <text style={{
                        marginLeft: 4,
                        color: "darkgrey"
                    }}>PLAYER
                    </text>
                    <text style={{
                        textAlign: "end",
                        width: "48vw",
                        color: "darkgrey"
                    }}>P
                    </text>
                    <text style={{
                        marginLeft: 16,
                        width: 16,
                        color: "darkgrey"
                    }}>C
                    </text>
                    <text style={{
                        marginLeft: 20,
                        width: 24,
                        color: "darkgrey",
                    }}>PTS
                    </text>
                </div>
                <div
                    className="vertical-scroll-leaderboard"
                    ref={scrollViewRef}
                    style={{userSelect: 'none', height: height, overflowY: 'auto'}}
                >
                    <div>
                        {scores.map((item, index) => (
                            <div key={index}>
                                <LeaderboardSlot placement={(index + 1).toString()} username={item[0]}
                                                 predictAmount={item[1]} correctPredictAmount={item[2]}
                                                 totalPoints={item[3]} id={item[4]}></LeaderboardSlot>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{
                    display: "grid",
                    justifyItems: "center",
                    alignItems: "center",
                }}>
                    <img src={require("./Images/BackButton.png")} style={{
                        rotate: "270deg"
                    }}/>
                </div>
            </div>)}

            </>
        )
};

export default ScrollContainerVerticalForLeaderboard;
import React, {useEffect} from 'react';
import Loading from "./Loading";
import {AutoTextSize} from "auto-text-size";

interface podiumSlotProps {
    length:number;
    placement:number;
    userD:string[];
}

const PodiumSlot: React.FC<podiumSlotProps> = ({length,placement,userD}) => {

    const [showPodium, setShowPodium] = React.useState(false);

    useEffect(() => {
        setShowPodium(userD !== undefined)
    }, [userD]);

    return (
        <div>
            {!showPodium && <Loading></Loading>}
            {showPodium && <div>
                <div style={{
                    display: "flow",
                    alignItems: "center",
                    justifyItems: "center",
                }}>
                    <div>
                        <img src={require("./Images/ProfilePic.png")} alt={"PP1 icon"} style={{
                            border: "1px solid #FFCC00",
                            backgroundColor: "#2F2F2F",
                            borderRadius: 8,
                            height: length,
                            width: length
                        }}></img>
                    </div>
                    <div>
                        <AutoTextSize minFontSizePx={12} maxFontSizePx={18}>
                            {placement}.{userD[0]} <br/> {userD[3]} PTS</AutoTextSize>
                    </div>
                </div>
            </div>}
        </div>
);
};

export default PodiumSlot;
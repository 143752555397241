import React, {useEffect} from 'react';
import {auth} from "./config/firebase";

interface ListProps  {
    placement: string
    username: string;
    predictAmount:string,
    correctPredictAmount:string,
    totalPoints:string,
    id:string
}

const LeaderboardSlot:React.FC<ListProps> = ({placement,username,predictAmount,correctPredictAmount,totalPoints,id}) => {
    
    const [textColor, setTextColor] = React.useState<string>("white");
    
    //@ts-ignore
    const userId = auth.currentUser?.uid;

    useEffect(() => {
        if (id === userId){
            setTextColor("gold")
        }
        else
            setTextColor("white")
    }, [id, userId]);
    
    
    return (
        <div className={"leaderboardSlot"}>
            <text style={{
                width:20,
            }}>{placement}</text>
            <img src={require("./Images/ProfilePic.png")} alt={"profile pic"} style={{
                width:25,
                height:25,
                marginLeft: 8,
            }}></img>
            <text style={{
                width: "50vw",
                textAlign: "start",
                marginLeft: 8,
                color: textColor
            }}>{username}
            </text>
            <text style={{
                width: 16,
            }}>{predictAmount}
            </text>
            <text style={{
                marginLeft: 16,
                width: 16,
                justifySelf: "end"
            }}>{correctPredictAmount}
            </text>
            <text style={{
                marginLeft: 16,
                marginRight:10,
                width: "6vw",
                textAlign: "end"
            }}>{totalPoints}
            </text>
        </div>
    );
};

export default LeaderboardSlot;
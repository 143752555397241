import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {collection, getDocs} from "firebase/firestore";
import {auth, firestore} from "./config/firebase";
import ScrollContainerVerticalForPrivateLeagues from "./ScrollContainerVerticalForPrivateLeagues";


const JoinedLeaguesPage = () => {

    const [leagues, setLeagues] = React.useState<string[][]>([]);
    const [enableLeagues, setEnableLeagues] = React.useState(false);
    const [openJoinedLeagues, setOpenJoinedLeague] = React.useState(false);
    const [joinedLeagues, setJoinedLeague] = React.useState<string[]>([]);
    const [userData, setUserData] = React.useState([] as any);

    const privateLeaguesCollection = collection(firestore,"privateLeagues");
    const userD = collection(firestore,"users");

    const userId = auth.currentUser?.uid;

    const getPredictionData = async () => {

        try {
            const prediction = await getDocs(userD);
            const simplified = prediction.docs.map((doc) => ({...doc.data(),id:doc.id}));

            for (let i = 0; i < simplified.length; i++) {
                if (simplified[i].id === userId)
                { // @ts-ignore
                    setUserData(simplified[i].predictions)
                }
            }
        }
        catch (err){
            console.log(err)
        }
    }

    const getPrivateLeagues = async () => {

        const privateLeaguesFS = await getDocs(privateLeaguesCollection);
        const simplified = privateLeaguesFS.docs.map((doc) => ({...doc.data(),id:doc.id}));

        if (simplified.length > 0){
            for (const privateLeague of simplified) {
                // @ts-ignore
                for (let i = 0; i < privateLeague.users.length; i++) {
                    // @ts-ignore
                    if (privateLeague.users[i] === userId) {
                        // @ts-ignore
                        leagues.push(privateLeague);
                    }
                }
            }

        }
    }


    useEffect(() => {
        if(leagues.length > 0)
            setEnableLeagues(true)
        else
            setEnableLeagues(false)

    }, [leagues]);

    useEffect(() => {
        getPredictionData()
        getPrivateLeagues()
    }, []);

    return (
        <div>
            {!openJoinedLeagues && <div>
                <div style={{
                    marginTop: 16,
                    display: 'grid',
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <NavLink to={"/leagues"} className={"backButton"}></NavLink>
                        <h2 style={{
                            width: 310
                        }}>LEAGUES</h2>
                    </div>
                    <h4 className={"subInfo"}>Create your league, compete with your friends, enjoy the rewards</h4>
                </div>
                {!enableLeagues && <div style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "48px"
                }}>
                    <img src={require("./Images/NoLeague.png")} alt={"no league image"} style={{
                        justifySelf: "center",
                        alignSelf: "center"
                    }}></img>
                    <text style={{
                        color: "darkgrey"
                    }}> You haven't joined any league
                    </text>
                </div>}
                {enableLeagues && <div style={{
                    paddingLeft:7
                }}>
                    {leagues.map((item, index) => (
                        <div key={index}>
                            <button className={"createLeagueButton"} onClick={() => {
                                setJoinedLeague(leagues[index]);
                                setOpenJoinedLeague(true);
                                leagues.splice(0,leagues.length-1)
                            }
                            } style={{
                                color: "white"
                                //@ts-ignore
                            }}>{leagues[index].leagueName}</button>
                        </div>
                    ))}
                </div>}
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <NavLink to={"/leagues/create"} className={"createLeagueButton active"}>Create a league</NavLink>
                </div>
            </div>}
            {openJoinedLeagues && <div style={{
                marginTop: 16,
            }}>
                <div style={{
                    display: "flex"
                }}>
                    <button onClick={() => setOpenJoinedLeague(false)} className={"backButton"}></button>
                    <div>
                        <text className={"subInfo"}>Fun way to compete.</text>
                    </div>
                </div>
                <ScrollContainerVerticalForPrivateLeagues leagueDetails={joinedLeagues} predictionData={userData}></ScrollContainerVerticalForPrivateLeagues>

            </div>}
        </div>
    );
};


export default JoinedLeaguesPage;
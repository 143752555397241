import React, {ChangeEvent, useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import InvitePanel from "./InvitePanel";
import {auth, firestore} from "./config/firebase";
import {collection, doc, getDocs, setDoc} from "firebase/firestore";
import {AutoTextSize} from "auto-text-size";

let buttonClassName:string = "createLeagueButton"

const CreateLeague = () => {

    const usersFS = collection(firestore,"users");
    const [isSettingsDone, setIsSettingsDone] = useState(false);
    const [leagueName, setLeagueName] = useState("");
    const [reward, setReward] = useState<string>('');
    const [classname1, setClassname1] = React.useState('categoryItems active');
    const [classname2, setClassname2] = React.useState('categoryItems');
    const [classname3, setClassname3] = React.useState('categoryItems');
    const [chosenLeague, setChosenLeague] = React.useState('TSL');
    const [users, setUsers] = React.useState<string[]>([]);
    const [userTag, setUserTag] = React.useState<string>("");
    const [userData, setUserData] = React.useState([] as any);
    const [userInviteEnabled, setUserInviteEnabled] = React.useState(false);
    const navigate = useNavigate();
    const [warning, setWarning] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);
    const [buttonSize, setButtonSize] = React.useState<number>(114);

    const buttons = Array.from({ length: 10 }, (_, index) => ({
        id:index,
        index: index + 1,
    }));

    const SetButtonSize = () => {
        setButtonSize(window.innerHeight/10)
    }

    const handleInputChangeLeague = (event: ChangeEvent<HTMLInputElement>) => {
        setLeagueName(event.target.value);
    };const handleInputChangeReward = (event: ChangeEvent<HTMLInputElement>) => {
        setReward(event.target.value);
    };
    const InvitePanelOpen = () => {
        setUserInviteEnabled(true)
    }
    const handleInputChangeInvite = (event: ChangeEvent<HTMLInputElement>) => {
        setUserTag(event.target.value);
    };
    const handleUserTags = () =>{
        setClicked(!clicked)
        for (let i = 0; i < userData.length; i++) {
            if (userData[i].nickname !== userTag) {
                setWarning(true)
            }
            else {
                if (users.length > 0){
                    for (let j = 0; j < users.length; j++) {
                        if (users[j] === userTag) {
                            setWarning(true)
                            break;
                        } else {
                            setWarning(false)
                        }
                    }
                }
                else
                    setWarning(false)
                break
            }
        }
    }

    useEffect(() => {
        if (userInviteEnabled){
            if (!warning){
                users.push(userTag)
                setUserInviteEnabled(false)
                setUserTag("")
            }
        }
    }, [clicked]);

    const getUserData = async () => {
        const userIDFS = await getDocs(usersFS);
        const simplified = userIDFS.docs.map((doc) => ({...doc.data(),id:doc.id}));
        setUserData(simplified);
    }

    useEffect(() => {
        SetButtonSize();
        getUserData();
    }, []);

    const CreateLeague = async () => {
        //@ts-ignore
        const userId = auth.currentUser?.uid;
        // @ts-ignore
        const idIndicator = userId.substring(0,6);
        users.push(idIndicator)

        const userIds:string[] = [];
        for (const id1 of users) {
            for (const id2 of userData) {
                if (id2.nickname.includes(id1))
                    userIds.push(id2.id)
            }
        }
        const leagueDetails = {
            leagueName:leagueName,
            users:userIds,
            leagueType:chosenLeague,
            reward:reward
        }

        if (userIds.length === users.length) {
            const privateLeaguesFS = doc(collection(firestore, 'privateLeagues'),leagueDetails.leagueName);
            await setDoc(privateLeaguesFS, leagueDetails, { merge: true });

            navigate('/leagues/joined');
        }
    }

    const handleActivate1 = () => {
        setClassname1("categoryItems active")
        setClassname2("categoryItems")
        setClassname3("categoryItems")
        setChosenLeague("TSL")
    }
    const handleActivate2 = () => {
        setClassname1("categoryItems")
        setClassname3("categoryItems")
        setClassname2("categoryItems active")
        setChosenLeague("CL")
    }
    const handleActivate3 = () => {
        setClassname1("categoryItems")
        setClassname2("categoryItems")
        setClassname3("categoryItems active")
        setChosenLeague("EPL")
    }

    useEffect(() => {
        if (chosenLeague !== null && reward !== "" && leagueName !== "" && users.length > 0)
            setIsSettingsDone(true)
        else
            setIsSettingsDone(false);

    }, [chosenLeague, leagueName, reward]);

    useEffect(() => {
        if (isSettingsDone)
            buttonClassName = "createLeagueButton active";
        else
            buttonClassName = "createLeagueButton";
    }, [isSettingsDone]);

    return (
        <div>
            <div style={{
                display: 'grid',
            }}>
                <div style={{
                    display:"flex",
                    gap: window.innerWidth/8,
                }}>
                    <NavLink to={"/leagues/joined"} className={"backButton"}></NavLink>
                    <text className={"bigHeader"} >CREATE A LEAGUE</text></div>
                    <text className={"subInfo"}>Create your league, compete with your friends, enjoy the rewards</text>
            </div>
            <div style={{
                marginTop: 12,
            }}>
                <div style={{
                    display: "grid",
                    textAlign: "left"
                }}>
                    <text className={"headerText"}>League Name</text>
                    <input
                        type="text"
                        value={leagueName}
                        onChange={handleInputChangeLeague}
                        placeholder="Set your leagues' name"
                        className={"rewardText"}
                        style={{
                            paddingTop:0
                        }}></input>
                </div>
                <div style={{
                    display: "grid",
                    textAlign: "left",
                    marginTop:8
                }}>
                    <text className={"headerText"}>Choose the tournament</text>
                    <text className={"subInfo"}>Which league will be your battleground? Choice is yours</text>
                </div>
                <div className={"buttonContainer"}>
                    <button onClick={handleActivate1} className={classname1}>
                        <img src={require("./Images/tslIcon.png")} alt="team1Img" style={{
                            width: 24,
                            height: 24
                        }}></img>
                        TSL
                    </button>
                    <button onClick={handleActivate2} className={classname2}>
                        <img src={require("./Images/clIcon.png")} alt="team2Img" style={{
                            width: 24,
                            height: 24
                        }}></img> CL
                    </button>
                    <button onClick={handleActivate3} className={classname3}>
                        <img src={require("./Images/eplIcon.png")} alt="team3Img" style={{
                            width: 24,
                            height: 24
                        }}></img> EPL
                    </button>
                </div>
            </div>
            <div style={{
                display: 'grid',
                marginTop: 8,
            }}>
                <div style={{
                    display: "grid",
                    textAlign: "left",
                }}>
                    <text className={"headerText"}>Invite your friends</text>
                    <text className={"subInfo"}>Invite your friends to compete.</text>
                </div>
            </div>
            <div className="inviteUserContainer">
                {buttons.map((button) => (
                    <button id={button.index.toString()} onClick={InvitePanelOpen} className={"inviteUserButton"} disabled={(users[button.index-1] === "")} style={{
                        width:buttonSize,
                        height:buttonSize
                    }}><text style={{
                        display:"flex",
                        paddingTop:40,
                        justifyContent:"center",
                        fontSize:"8px"
                    }}>{users[button.index-1]}</text>
                    </button>
                ))}
            </div>
            <div style={{
                display: "grid",
                justifyContent:"left",
                textAlign: "left",
                marginTop:8
            }}>
                <text className={"headerText"}>Set a reward</text>
                <text className={"subInfo"}>Determine the prize the winner of the tournament will receive.</text>
                <input type="text"
                    value={reward}
                    onChange={handleInputChangeReward}
                    placeholder="We recommend choosing a fun reward. 🤪"
                    className={"rewardText"}
                       style={{
                           width:"93vw",
                           paddingTop:0
                       }}
                    ></input>
                <button className={buttonClassName} disabled={!isSettingsDone} onClick={CreateLeague} style={{
                    width:"95vw",
                    height:buttonSize/1.5
                }}>Create a league</button>
            </div>
            <div style={{
                textAlign: "left"
            }}>
                <InvitePanel isOpen={userInviteEnabled} close={() => {setUserInviteEnabled(false)
                    setUserTag("")}}>
                    <div style={{
                        marginLeft: 14
                    }}>
                        <p className={"headerText"}>Invite your friend.</p>
                        <p className={"subInfo"}>Enter your friends name with tag</p>
                        <input type="text"
                               value={userTag}
                               onChange={handleInputChangeInvite}
                               placeholder="Enter nickname with #"
                               className={"rewardText"}
                               style={{
                                   paddingTop:0,
                                   marginTop:8,
                                   width:"322px"
                               }}
                        ></input>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 10
                    }}>
                        <button className={"createLeagueButton"} onClick={() => setUserInviteEnabled(false)} style={{
                            width: "160px",
                            height: "30px",
                            fontWeight:"normal",
                            fontSize:15
                        }}>Close
                        </button>
                        <button className={"createLeagueButton active"} onClick={handleUserTags} style={{
                            width: "160px",
                            height: "30px",
                            fontWeight:"normal",
                            fontSize:15
                        }}>Invite
                        </button>
                    </div>
                </InvitePanel>
                <InvitePanel isOpen={warning} close={() => {}}>
                    <div>
                        <AutoTextSize minFontSizePx={12} maxFontSizePx={18} style={{
                            alignItems: "center",
                            marginTop:15,
                            marginLeft: 30,
                            justifyContent: "center",
                            width: 300,
                            textAlign: "center"
                        }}>User does not exist or already added to invite list, please enter the guest name
                            and tag correctly</AutoTextSize>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <button className={"createLeagueButton"} style={{
                            width: "160px",
                            height: "30px"
                        }} onClick={() => setWarning(false)}>Close
                        </button>
                    </div>
                </InvitePanel>
            </div>
        </div>
    );
};

export default CreateLeague;
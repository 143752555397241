import React, {useEffect, useRef, useState} from "react";

interface ListProps  {
    itemsList: string[][];
}

const ScrollContainerHorizontal: React.FC<ListProps>= ({itemsList}) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [filteredItems, setFilteredItems] = useState(itemsList);

    useEffect(() => {
        const filtered = itemsList.filter(item => {
            // @ts-ignore
            return item.STAGE_TYPE === "LIVE";
        });

        setFilteredItems(filtered);

    }, [itemsList]);

    return (
        <div>
            <div
                ref={containerRef}
                className="scroll-container-horizontal"
                style={{ overflow: 'auto', whiteSpace: 'nowrap' }}
            >
                    {filteredItems.length > 0 &&filteredItems.map((
                        //@ts-ignore
                        item, index) => (
                        <div key={index} ref={el => itemRefs.current[index] = el} className={"liveMatchSlot"}
                        >
                            <div style={{
                                width: '100%',
                                height: '100%',
                                textDecoration: "none"
                            }}>
                                <div className={"match-overlay"}>
                                    <div className={"match-container"}>
                                        <h2 style={{
                                            fontSize: 12,
                                            color: "white"
                                        }}>{//@ts-ignore
                                            item.SHORTNAME_HOME}</h2>
                                        <img src={
                                            //@ts-ignore
                                            item.HOME_IMAGES} className={"circle-flag"} style={{
                                                width:36,
                                                height:36
                                        }}></img>
                                        <text style={{
                                            backgroundColor:"black",
                                            color:"white",
                                            borderRadius:"5px"
                                        }}>{//@ts-ignore
                                            item.HOME_SCORE_CURRENT}-{item.AWAY_SCORE_CURRENT}</text>
                                        <img src={
                                            //@ts-ignore
                                            item.AWAY_IMAGES} className={"circle-flag"} style={{
                                            width:36,
                                            height:36,
                                            marginLeft:10
                                        }}></img>
                                        <h2 style={{
                                            fontSize: 12,
                                            color: "white"
                                        }}>{//@ts-ignore
                                            item.SHORTNAME_AWAY}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                {filteredItems.length === 0 && <div>
                    <text className={"bigHeader"}>No live matches at the moment!</text>
                </div>
                }

            </div>
        </div>
    );
};

export default ScrollContainerHorizontal;
import React, {useEffect, useState} from 'react';
import HorizontalNumberSlider from "./HorizontalNumberSlider";
import {collection, doc, setDoc} from 'firebase/firestore';
import {auth, firestore} from "./config/firebase";
import {AutoTextSize} from "auto-text-size";
import {decreaseDoubleP, decreaseRePred, doubleP, rePred} from "./ScrollContainerVerticalForMatchSlots";

interface teams  {
    matchId:string;
    team1:string;
    team2:string;
    team1ImgUrl:string;
    team2ImgUrl:string;
    score1:string;
    score2: string;
    matchTime:number;
    predictions:string[][];
    matchOfTheDay:string[][] | null;
}
const MatchSlot: React.FC<teams>= ({matchId, team1,team2,team1ImgUrl,team2ImgUrl, score1,score2,matchTime,predictions,matchOfTheDay}) => {

    const [scoreForTeam1, setScoreForTeam1] = useState<string>();
    const [predictForTeam1, setPredictionForTeam1] = useState<string>();
    const [scoreForTeam2, setScoreForTeam2] = useState<string>();
    const [predictForTeam2, setPredictionForTeam2] = useState<string>();
    const [liveScore1, setLiveScore1] = useState<string>();
    const [liveScore2, setLiveScore2] = useState<string>();
    const [noPrediction, setNoPrediction] = useState(true);
    const [height, setHeight] = useState<number>(120);
    let [doublePoint, setDoublePoint] = useState<number>(doubleP);
    let [rePredictAmount, setRePredictAmount] = useState<number>(rePred);
    const [activateScoreSelection, setActivateScoreSelection] = useState<boolean>(false);
    const [rePredictable, setRePredictable] = useState<boolean>(false);
    const [enableDouble, setEnableDouble] = useState<boolean>(false);
    const [doublePointActivated, setDoublePointActivated] = useState<boolean>(false);
    let [matchOfDay, setMatchOfDay] = useState<boolean>(false);
    const [predictEnable, setPredictEnable] = useState<boolean>(false);
    const [predictLockable , setPredictLockable] = useState<boolean>(false);
    const [matchLive, setMatchLive] = useState<boolean>(false);
    const [matchDone, setMatchDone] = useState<boolean>(false);
    const [pointsEarned, setPointsEarned] = useState<number>(0);

    //@ts-ignore
    const userId = auth.currentUser?.uid;

    let date = new Date(matchTime * 1000);

    const updateUserPrediction = async (userId: string | undefined, predict: {
        [p: string]: {
            doublePoint: boolean;
            team1: string;
            team2: string;
            prediction1: string | undefined;
            prediction2: string | undefined
        }
    }): Promise<void> => {
        const userPredictionDocRef = doc(collection(firestore, 'users'),userId);
        await setDoc(userPredictionDocRef, {predictions:predict},{merge: true });
    };
    const updateUserRePrediction = async (userId:string | undefined, rePredict:number): Promise<void> => {
        const userPredictionDocRef = doc(collection(firestore, 'users'),userId);
        await setDoc(userPredictionDocRef,  {rePredictAmount: rePredict},{merge: true});
    };
    const updateUserDoublePoint = async (userId:string | undefined, doublePoint:number): Promise<void> => {
        const userPredictionDocRef = doc(collection(firestore, 'users'),userId);
        await setDoc(userPredictionDocRef,{doublePoint: doublePoint},{merge: true});
    };

    const fetchUserPredictions = () => {
        if (predictions !== undefined && Object.keys(predictions).length > 0){
            for (let i = 0; i < Object.keys(predictions).length; i++) {
                //@ts-ignore
                if (Object.values(predictions)[i].team1 === team1 && Object.values(predictions)[i].team2 === team2){
                    //@ts-ignore
                    setScoreForTeam1(Object.values(predictions)[i].prediction1)
                    //@ts-ignore
                    setScoreForTeam2(Object.values(predictions)[i].prediction2)

                    setPredictEnable(true)
                    setNoPrediction(false)
                }
            }
        }
        else{
            setPredictEnable(false)
            setNoPrediction(true)
        }
    };

    useEffect(() => {
        fetchUserPredictions();
        
    }, [fetchUserPredictions]);

    useEffect(() => {
        if (predictForTeam1 !== undefined && predictForTeam2 !== undefined){
            setPredictLockable(true);
            setScoreForTeam1(predictForTeam1)
            setScoreForTeam2(predictForTeam2)
        }
        else
            setPredictLockable(false)
    }, [predictForTeam1, predictForTeam2]);


    useEffect(() => {
        setDoublePoint(doubleP)
    }, [doubleP]);

    useEffect(() => {
        setRePredictAmount(rePred)
    }, [rePred]);

    useEffect(()=>{
        setRePredictable(rePredictAmount > 0);
    }, [rePredictAmount])

    useEffect(()=>{
        setEnableDouble(doublePoint > 0);
    }, [doublePoint])

    useEffect(() => {
        if(new Date().getTime()>date.getTime()){
            setMatchLive(true)
            setPredictEnable(true)
            setLiveScore1(score1);
            setLiveScore2(score2);
        }
        else{
            setMatchLive(false)
        }
    }, [date, score1, score2]);

    useEffect(() => {
        if (new Date().getTime()/60000-date.getTime()/60000>90){
            setMatchDone(true)
            setScoreForTeam1(predictForTeam1)
            setScoreForTeam2(predictForTeam2)

            if (predictions && Object.keys(predictions).length > 0){
                for (let k = 0; k < Object.keys(predictions).length; k++) {
                    // @ts-ignore
                    if (Object.keys(predictions)[k] === team1 + team2) {
                        // @ts-ignore
                        if (Object.values(predictions)[k].prediction1 === score1 && Object.values(predictions)[k].prediction2 === score2) {
                            setPointsEarned(5)
                        } else {
                            // @ts-ignore
                            if ((Object.values(predictions)[k].prediction1 - Object.values(predictions)[k].prediction2 === score1 - score2)) {
                                setPointsEarned(2)
                            }
                            // @ts-ignore
                            else if ((Object.values(predictions)[k].prediction1 > Object.values(predictions)[k].prediction2 && score1 > score2) || (Object.values(predictions)[k].prediction1 < Object.values(predictions)[k].prediction2 && score1 < score2) || (Object.values(predictions)[k].prediction1 === Object.values(predictions)[k].prediction2 && score1 === score2)) {
                                // @ts-ignore
                                if (Object.values(predictions)[k].doublePoint)
                                    setPointsEarned(1);
                            }
                        }
                        // @ts-ignore
                        if (Object.values(predictions)[k].doublePoint)
                            setPointsEarned(pointsEarned*2);
                        // @ts-ignore
                        if (Object.values(predictions)[k].matchOfTheDay)
                            setPointsEarned(pointsEarned*2);
                    }
                }
            }

        }
        else{
            setMatchDone(false)
            setScoreForTeam1(predictForTeam1)
            setScoreForTeam2(predictForTeam2)
        }
    },[predictForTeam1, predictForTeam2,matchTime]);

    const handleSelectTeam1 = (number: number) => {
        setPredictionForTeam1(number.toString())
    };
    const handleSelectTeam2 = (number: number) => {
        setPredictionForTeam2(number.toString())
    };

    const handleScoreSetterActivation = () => {
        setActivateScoreSelection(!activateScoreSelection);
        if (!activateScoreSelection){
            setHeight(190)
            //@ts-ignore
            if (matchOfTheDay.HOME_PARTICIPANT_NAME_ONE === team1 && matchOfTheDay.AWAY_PARTICIPANT_NAME_ONE === team2){
                matchOfDay = true
            }
            else
                matchOfDay = false
        }
        else
        {
            setPredictionForTeam1(undefined)
            setPredictionForTeam2(undefined)
            if (scoreForTeam1 !== undefined && scoreForTeam2 !== undefined)
            {
                setScoreForTeam1(undefined);
                setScoreForTeam2(undefined);
            }

            setHeight(120)
        }
    }

    const doublePointActive = () => {
        setDoublePointActivated(!doublePointActivated)
    }
    
    const handleSavePrediction = async () =>{
        setPredictEnable(true);
        setScoreForTeam1(predictForTeam1);
        setScoreForTeam2(predictForTeam2);

        const prediction = {
            [team1+team2]:{
                team1:team1,
                team2:team2,
                prediction1: scoreForTeam1,
                prediction2: scoreForTeam2,
                doublePoint:doublePointActivated,
                matchOfTheDay:matchOfDay
            }
        };
        if (rePredictable && scoreForTeam1 !== undefined && scoreForTeam2 !== undefined){
            rePredictAmount--;
            setRePredictAmount(rePredictAmount)
            decreaseRePred();
            await updateUserRePrediction(userId,rePredictAmount)
        }
        if (doublePointActivated){
            doublePoint--;
            setDoublePoint(doublePoint)
            decreaseDoubleP();
            await updateUserDoublePoint(userId,doublePoint)
        }
        await updateUserPrediction(userId,prediction);
        setActivateScoreSelection(!activateScoreSelection);
        setHeight(160)
    }

    return (
        <div className="matchSlot" style={{
            height:`${height}px`
        }}>
            <div>
                <div style={{
                    display: "flow",
                    alignItems: "center",
                    justifyItems: "center",
                    gap: "10px",
                }}>
                    <div>
                        <img src={team1ImgUrl} className={"circle-flag"} alt="team1Img"></img>
                    </div>
                    <div>
                        <AutoTextSize minFontSizePx={12} maxFontSizePx={18} style={{
                            width:113,
                            color: "white",
                            marginBottom:10
                        }}>{team1}</AutoTextSize>
                    </div>
                </div>
                {activateScoreSelection && <HorizontalNumberSlider min={0} max={10} onSelect={handleSelectTeam1}/>}
            </div>
            <div>
                {!matchDone && <div>
                    {!matchLive && <div>
                        {predictEnable && <h6 className={"subInfo"} style={{
                            marginTop:16
                        }}>Your Prediction:</h6>}
                        {!predictEnable && <h6 style={{
                            color: "white",
                            marginTop:16
                        }}> V </h6>}
                        {predictEnable && <h3> {scoreForTeam1 +" - " +scoreForTeam2}</h3>}
                        {!matchLive && <h6 className={"subInfo"}>{date ? date.toLocaleTimeString([], { day:"numeric",month:"numeric",year:"numeric",hour: '2-digit', minute: '2-digit',hour12: false}):""}</h6>}
                    </div>}
                    {matchLive && <div style={{
                        marginTop:10
                    }}>
                        {predictEnable && <h6 style={{
                            color: "#00FF1A",
                            fontSize: "20px",
                            marginTop:8
                        }}>{liveScore1}-{liveScore2}</h6>}
                        {predictEnable && !noPrediction && <text className={"subInfo"} style={{
                            bottom:0
                        }}
                        >Your Prediction: <text style={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}>{scoreForTeam1 + " - " + scoreForTeam2}</text>
                        </text>}
                        {predictEnable && noPrediction && <text className={"subInfo"} style={{
                            bottom:0
                        }}
                        >No prediction
                        </text>}
                    </div>}
                </div>}
                {matchDone && <div>
                    <text className={"bigHeader"}>{liveScore1}-{liveScore2}</text>
                </div>}
                {!matchDone && <div>
                    {!predictEnable && <div>
                        {!activateScoreSelection &&
                            <button onClick={handleScoreSetterActivation} className={"predictNowButton"}>Predict Now
                            </button>}
                    </div>}
                    {predictEnable && rePredictable && !activateScoreSelection && !matchLive &&
                        <button onClick={handleScoreSetterActivation} className={"predictNowButton"} style={{
                            marginTop:0
                        }}>Re-predict
                        </button>}
                </div>}
                {matchDone && !noPrediction &&
                    <div>
                        <text className={"subInfo"}>Your prediction: {scoreForTeam1}-{scoreForTeam2}</text>
                        <h2 className={"subInfo"} style={{
                            fontSize:8,
                            fontWeight:"normal"
                        }}>Score from prediction: {pointsEarned}</h2>
                    </div>

                }
                {matchDone && noPrediction &&
                    <text className={"subInfo"}>No Prediction</text>
                }
            </div>
            <div>
                <div style={{
                    display: "flow",
                    alignItems: "center",
                    justifyItems: "center",
                    gap: "10px",
                }}>
                    <div>
                        <img src={team2ImgUrl} className={"circle-flag"} alt="team2Img"></img>
                    </div>
                    <div>
                        <AutoTextSize minFontSizePx={12} maxFontSizePx={18} style={{
                            width: 113,
                            color: "white",
                            marginBottom: 10
                        }}>{team2}</AutoTextSize>
                    </div>
                </div>
                {activateScoreSelection && <HorizontalNumberSlider min={0} max={10} onSelect={handleSelectTeam2}/>}
            </div>
            {activateScoreSelection && !enableDouble && <div style={{
                display: "flex",
                marginBottom: 12,
                justifyContent: "center",
                alignItems: "center",
                width: "337.5px",
                gap: "10px",
            }}>
                <button onClick={handleScoreSetterActivation} className={"categoryItems"} style={{
                    width: 160,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "white",
                    fontSize: "12px"
                }}>Cancel
                </button>
                <button onClick={handleSavePrediction} disabled={!predictLockable} className={"categoryItems"} style={{
                    width: 160,
                    backgroundColor: "#FFCC00",
                    color: "#1F2F79",
                    fontSize: "12px"
                }}>{predictLockable && "Predict Now"} {!predictLockable && "Make prediction"}</button>
            </div>}
            {activateScoreSelection && enableDouble && <div style={{
                display: "flex",
                marginBottom: 12,
                justifyContent: "center",
                alignItems: "center",
                width: "337.5px",
                gap: "10px",
            }}>
                <button onClick={handleScoreSetterActivation} className={"categoryItems"} style={{
                    width: 110,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "white",
                    fontSize: "12px"
                }}>Cancel
                </button>
                {!doublePointActivated && <button onClick={doublePointActive} className={"categoryItems"} style={{
                    width: 110,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "white",
                    fontSize: "12px"
                }}>Double points
                </button>}
                {doublePointActivated && <button onClick={doublePointActive} className={"categoryItems active"} style={{
                    width: 110,
                    fontSize: "12px"
                }}>Double points
                </button>}
                <button onClick={handleSavePrediction} disabled={!predictLockable} className={"categoryItems"} style={{
                    width: 110,
                    backgroundColor: "#FFCC00",
                    color: "#1F2F79",
                    fontSize: "12px"
                }}>{predictLockable && "Predict Now"} {!predictLockable && "Make prediction"}</button>
            </div>}
        </div>
    );
};

export default MatchSlot;
import React, {useEffect, useState} from 'react';
import ScrollContainerVerticalForMatchSlots from "./ScrollContainerVerticalForMatchSlots";
import {collection, getDocs} from "firebase/firestore";
import {
    auth,
    championsHistoryMatchData,
    championsMatchData, eplHistoryMatchData, eplMatchData,
    firestore,
    turkeyHistoryMatchData,
    turkeyMatchData
} from "./config/firebase";
import Loading from "./Loading";

type MatchData = {
    id: string;
    team1: string;
    team2: string;
    team1ImgUrl:string;
    team2ImgUrl:string;
    score1: number;
    score2: number;
    matchHour: string;
};

const PredictionPage = () => {

    const predictions = collection(firestore,"users");
    const matchOfTheDayRef = collection(firestore,"matchOfTheDay");
    const [predictionData, setPredictionData] = useState([] as any);
    const [activeScrollPrediction, setActiveScrollTab] = useState(true);
    const [activeScroll1, setActiveScroll1] = useState(true);
    const [activeScroll2, setActiveScroll2] = useState(false);
    const [activeScroll3, setActiveScroll3] = useState(false);
    const [matches1, setMatches1] = useState([] as any);
    const [historyMatches1, setHistoryMatches1] = useState([] as any);
    const [matches2, setMatches2] = useState([]as any);
    const [historyMatches2, setHistoryMatches2] = useState([]as any);
    const [matches3, setMatches3] = useState([]as any);
    const [historyMatches3, setHistoryMatches3] = useState([]as any);
    const [classname1, setClassname1] = useState('categoryItems active');
    const [classname2, setClassname2] = useState('categoryItems');
    const [classname3, setClassname3] = useState('categoryItems');
    const [activeButton1, setActiveButton1] = React.useState("durationButton active")
    const [activeButton2, setActiveButton2] = React.useState("durationButton")
    const [isLoading, setIsLoading] = useState(true);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(200);

    const [league1ImgUrl, setLeague1ImgUrl] = useState('');
    const [league2ImgUrl, setLeague2ImgUrl] = useState('');
    const [league3ImgUrl, setLeague3ImgUrl] = useState('');

    const handleActivatePredictions = () => {
        setActiveButton1("durationButton active")
        setActiveButton2("durationButton")
        setActiveScrollTab(true)
        handleActivate1()
    }
    const handleActivateHistory = () => {
        setActiveButton1("durationButton")
        setActiveButton2("durationButton active")
        setActiveScrollTab(false)
        handleActivate1()
        setHistoryOpen(true)
    }

    useEffect(() => {
        setScrollHeight(window.innerHeight-205)
    }, []);

    //@ts-ignore
    const userId = auth.currentUser?.uid;

    const getMatchOfTheDay = async () => {
        try {
            const matchData = await getDocs(matchOfTheDayRef);
            const simplified = matchData.docs.map((doc) => ({...doc.data(),id:doc.id}))[0] as MatchData;
            return simplified;
        }
        catch (err){
            console.log(err)
        }
    };

    const getPredictions = async () => {
        setIsLoading(true)
        try {
            const prediction = await getDocs(predictions);
            const simplified = prediction.docs.map((doc) => ({...doc.data(),id:doc.id}));
            const filtered = simplified.filter((item) => {
                for (let i = 0; i < simplified.length; i++) {
                    if (item.id === userId){
                        return item;
                    }
                }
            });
            //@ts-ignore
            setPredictionData(filtered[0].predictions)

            turkeyMatchData().then(data => {
                //setLeague1ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
                const matches = data.DATA[0].EVENTS as string[][];
                const categorized = matches.filter((item) => {
                    //@ts-ignore
                    for (const predict of Object.values(filtered[0].predictions)) {
                        //@ts-ignore
                        if(predict.team1+predict.team2 === item.HOME_PARTICIPANT_NAME_ONE + item.AWAY_PARTICIPANT_NAME_ONE)
                        {
                            return predict;
                        }
                    }
                })
                if (categorized.length>0){
                    setMatches1(categorized)
                    setActiveScroll1(true)
                }
            })
            championsMatchData().then(data => {
                //setLeague2ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
                const matches = data.DATA[0].EVENTS as string[][];
                const categorized = matches.filter((item) => {
                    //@ts-ignore
                    for (const predict of Object.values(filtered[0].predictions)) {
                        //@ts-ignore
                        if(predict.team1+predict.team2 === item.HOME_PARTICIPANT_NAME_ONE + item.AWAY_PARTICIPANT_NAME_ONE)
                        {
                            return predict;
                        }
                    }
                })
                if (categorized.length>0){
                    setMatches2(categorized)
                }
            })
            eplMatchData().then(data => {
                //setLeague3ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
                const matches = data.DATA[0].EVENTS as string[][];
                const categorized = matches.filter((item) => {
                    //@ts-ignore
                    for (const predict of Object.values(filtered[0].predictions)) {
                        //@ts-ignore
                        if(predict.team1+predict.team2 === item.HOME_PARTICIPANT_NAME_ONE + item.AWAY_PARTICIPANT_NAME_ONE)
                        {
                            return predict;
                        }
                    }
                })
                if (categorized.length>0){
                    setMatches3(categorized)
                }
            })
        }
        catch (err){
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    };

    const getMatchHistory = async () => {
            try {
                turkeyHistoryMatchData().then(data => {
                    const matches = data.DATA[0].EVENTS as string[][];
                    if (matches.length>0){
                        setHistoryMatches1(matches)
                    }
                })
                championsHistoryMatchData().then(data => {
                    const matches = data.DATA[0].EVENTS as string[][];
                    if (matches.length>0){
                        setHistoryMatches2(matches)
                    }
                })
                eplHistoryMatchData().then(data => {
                    const matches = data.DATA[0].EVENTS as string[][];
                    if (matches.length>0){
                        setHistoryMatches3(matches)
                    }
                })
            }
            catch (err){
                console.log(err)
            }
        };

    useEffect(() => {
        getPredictions()
    }, []);

    useEffect(() => {
        if (historyOpen){
            getMatchHistory()
        }
    }, [historyOpen]);

    const handleActivate1 = () => {
        setActiveScroll1(true)
        setActiveScroll2(false)
        setActiveScroll3(false)
        setClassname1("categoryItems active")
        setClassname2("categoryItems")
        setClassname3("categoryItems")
    }
    const handleActivate2 = () => {
        setActiveScroll1(false)
        setActiveScroll2(true)
        setActiveScroll3(false)
        setClassname1("categoryItems")
        setClassname2("categoryItems active")
        setClassname3("categoryItems")
    }
    const handleActivate3 = () => {
        setActiveScroll1(false)
        setActiveScroll2(false)
        setActiveScroll3(true)
        setClassname1("categoryItems")
        setClassname2("categoryItems")
        setClassname3("categoryItems active")
    }

    return (
        <div>
            {isLoading ? (<Loading />) : (
                <div>
                    <div style={{
                        textAlign: "left",
                        marginTop: 16,
                        marginLeft: 10,
                    }}>
                        <h4 style={{}}>Predictions</h4>
                        <text className={"subInfo"}>Prizes will be received at the end of the tournament.</text>
                    </div>
                    <div style={{
                        marginTop: 16,
                        gap:20
                    }}>
                        <button onClick={handleActivatePredictions} className={activeButton1}>PREDICTIONS</button>
                        <button onClick={handleActivateHistory} className={activeButton2}>MATCH HISTORY</button>
                    </div>
                    <div className={"buttonContainer"} style={{
                        marginTop: 10,
                        marginLeft: 8,
                    }}>
                        <button onClick={handleActivate1} className={classname1}>
                            <img src={require("./Images/tslIcon.png")} alt="team1Img" style={{
                                width: 24,
                                height: 24
                            }}></img>
                            TSL
                        </button>
                        <button onClick={handleActivate2} className={classname2}>
                            <img src={require("./Images/clIcon.png")} alt="team2Img" style={{
                                width: 24,
                                height: 24
                            }}></img> CL
                        </button>
                        <button onClick={handleActivate3} className={classname3}>
                            <img src={require("./Images/eplIcon.png")} alt="team3Img" style={{
                                width: 24,
                                height: 24
                            }}></img> EPL
                        </button>
                    </div>
                    {activeScrollPrediction && <div>
                        {activeScroll1 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                  itemsList={matches1} predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                        {activeScroll2 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                   itemsList={matches2} predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                        {activeScroll3 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                   itemsList={matches3} predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                    </div>
                    }
                    {!activeScrollPrediction && <div>
                        {activeScroll1 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                   itemsList={historyMatches1} predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                        {activeScroll2 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                   itemsList={historyMatches2} predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                        {activeScroll3 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                   itemsList={historyMatches3} predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                    </div>
                    }
                </div>
            )}
        </div>
    );
};

export default PredictionPage;
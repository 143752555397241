import React, {useEffect, useRef} from 'react';
import LeaderboardSlot from "./LeaderboardSlot";
import {championsHistoryMatchData, eplHistoryMatchData, turkeyHistoryMatchData} from "./config/firebase";

// @ts-ignore
interface ListProps  {
    leagueDetails:string[]
    predictionData:string[];
}
type leagueDetails = {
    id:string;
    leagueName:string;
    leagueType:string;
    reward:string;
    users:string[];

}

const ScrollContainerVerticalForPrivateLeagues:React.FC<ListProps>= ({leagueDetails,predictionData}) => {

    const scrollViewRef = useRef<HTMLDivElement>(null);
    const [scores, setUserScores] = React.useState<string[][]>([]);
    const [items, setItems] = React.useState([]);

    let details = leagueDetails as unknown as leagueDetails

    const getMatches = async () => {
        if (details.leagueType === "TSL"){
            turkeyHistoryMatchData().then(data => {
                setItems(data.DATA[0].EVENTS);
            })
        }
        else if (details.leagueType === "CL"){
            championsHistoryMatchData().then(data => {
                setItems(data.DATA[0].EVENTS);
            })
        }
        else if (details.leagueType === "EPL"){
            eplHistoryMatchData().then(data => {
                setItems(data.DATA[0].EVENTS);
            })
        }
    }

    useEffect(() => {
        getMatches()
    }, []);

    useEffect(() => {
        const fetchData = async () => {

            let userD:string[][] = [];
            try {
                // @ts-ignore
                for (let i = 0; i < details.users.length; i++) {
                    let user: string[] = [];
                    let username = "Guest#" + details.users[i].substring(0,6);

                    let correctGuesses = 0;
                    let points = 0;
                    let predictions = 0;
                    for (let j = 0; j < items.length; j++) {
                        // @ts-ignore
                        for (let k = 0; k < Object.keys(predictions).length; k++) {
                            // @ts-ignore
                            if (Object.keys(predictions)[k].id === items[j].HOME_PARTICIPANT_NAME_ONE + items[j].AWAY_PARTICIPANT_NAME_ONE) {
                                predictions++;
                                // @ts-ignore
                                if (Object.values(predictions)[k].prediction1 === items[j].HOME_SCORE_CURRENT && Object.values(predictions)[k].prediction2 === items[j].AWAY_SCORE_CURRENT){
                                    correctGuesses++;
                                    points+=5;
                                    // @ts-ignore
                                    if (Object.values(predictions)[k].doublePoint)
                                        points+=5;
                                }
                                else{
                                    // @ts-ignore
                                    if ((Object.values(predictions)[k].prediction1-Object.values(predictions)[k].prediction2 === items[j].HOME_SCORE_CURRENT-items[j].AWAY_SCORE_CURRENT)){
                                        points+=2;
                                        // @ts-ignore
                                        if (Object.values(predictions.predictions)[k].doublePoint)
                                            points+=2;
                                    }
                                    // @ts-ignore
                                    else if((Object.values(predictions)[k].prediction1>Object.values(predictions)[k].prediction2 &&items[j].HOME_SCORE_CURRENT>items[j].AWAY_SCORE_CURRENT) || (Object.values(predictions)[k].prediction1<Object.values(predictions)[k].prediction2 &&items[j].HOME_SCORE_CURRENT<items[j].AWAY_SCORE_CURRENT)|| (Object.values(predictions)[k].prediction1===Object.values(predictions)[k].prediction2 &&items[j].HOME_SCORE_CURRENT===items[j].AWAY_SCORE_CURRENT)){
                                        points+=1
                                        // @ts-ignore
                                        if (Object.values(predictions)[k].doublePoint)
                                            points+=1;
                                    }
                                }

                            }
                        }
                    }
                    user = [username, Object.keys(predictions).toString(), correctGuesses.toString(), points.toString(),details.users[i]];
                    userD.push(user);
                }
                userD.sort((a, b) => parseInt(b[3]) - parseInt(a[3]));

                setUserScores(userD);

            } catch (err) {
                console.log(err);
            }

        };

        fetchData();
    }, []);
    return (
        <div>
            <>
                <div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 18
                    }}>
                        <text style={{
                            color: "darkgrey"
                        }}>#
                        </text>
                        <text style={{
                            marginLeft: 4,
                            color: "darkgrey"
                        }}>PLAYER
                        </text>
                        <text style={{
                            textAlign: "end",
                            width: "48vw",
                            color: "darkgrey"
                        }}>P
                        </text>
                        <text style={{
                            marginLeft: 16,
                            width: 16,
                            color: "darkgrey"
                        }}>C
                        </text>
                        <text style={{
                            marginLeft: 20,
                            width: 24,
                            color: "darkgrey",
                        }}>PTS
                        </text>
                    </div>
                    <div
                        className="vertical-scroll-leaderboard"
                        ref={scrollViewRef}
                        style={{userSelect: 'none', height: 80, overflowY: 'auto'}}
                    >
                        <div>
                            {scores.map((item, index) => (
                                <div key={index}>
                                    <LeaderboardSlot placement={(index + 1).toString()} username={item[0]}
                                                     predictAmount={item[1]} correctPredictAmount={item[2]}
                                                     totalPoints={item[3]} id={item[4]}></LeaderboardSlot>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{
                        display: "grid",
                        justifyItems: "center",
                        alignItems: "center",
                    }}>
                        <img src={require("./Images/BackButton.png")} style={{
                            rotate: "270deg"
                        }}/>
                    </div>
                </div>
                <div style={{
                    display: "grid",
                    textAlign: "left",
                    paddingTop: 18,
                    paddingLeft:18
                }}>
                    <h4>Reward</h4>
                    <text className={"subInfo"}>Which the winner will receive at the end of the tournament..</text>
                    <text className={"rewardText"}>{details.reward}
                    </text>
                </div>
            </>
        </div>
    );
};

export default ScrollContainerVerticalForPrivateLeagues;
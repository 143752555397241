import React, {useRef, useEffect, useState} from 'react';
import MatchSlot from "./MatchSlot";
import {collection, getDocs} from "firebase/firestore"
import {auth,firestore} from "./config/firebase";

// @ts-ignore
interface ListProps  {
    height: number;
    itemsList: string[][];
    predictions:string[][];
    matchOfTheDay:string[][] | null;
}

export let doubleP:number = 0;
export let rePred:number = 0;

export function decreaseDoubleP(){
    doubleP--;
}
export function decreaseRePred(){
    rePred--;
}

const ScrollContainerVerticalForMatchSlots: React.FC<ListProps>= ({height, itemsList,predictions,matchOfTheDay}) => {
    const scrollViewRef = useRef<HTMLDivElement>(null);
    const [filteredItems, setFilteredItems] = useState(itemsList);
    const users = collection(firestore,"users");

    const getWeekNumber = (date:Date) => {
        const copiedDate = new Date(date.getTime());

        copiedDate.setDate(copiedDate.getDate() + 4 - (copiedDate.getDay() || 7));

        const yearStart = new Date(copiedDate.getFullYear(), 0, 1);

        const weekNumber = Math.ceil((((copiedDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

        return weekNumber;
    };

    //@ts-ignore
    const userId = auth.currentUser?.uid;

    const getUserData = async () => {
        try {
            const userData = await getDocs(users);
            return userData.docs.map((doc) => ({...doc.data(), id: doc.id}));
        }
        catch (err){
            console.log(err)
        }
    }

    getUserData().then(data => {
        if (data !== undefined){
            for (let i = 0; i < data.length; i++) {
                if (userId === data[i].id){
                    //@ts-ignore
                    if (data[i].repredict !== undefined)
                        //@ts-ignore
                        rePred = data[i].repredict;
                    else
                        rePred =0
                    //@ts-ignore
                    if (data[i].doublePoint !== undefined)
                        //@ts-ignore
                        doubleP = data[i].doublePoint
                    else
                        doubleP =0
                }
            }
        }
    })

    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTop = 0;
        }

        const today = new Date();
        const weekOfToday = getWeekNumber(today);

        let closestWeekFound: number | null = null;
        let filtered: string[][] = [];

        itemsList.forEach(item => {
            if (closestWeekFound === null) {
                // @ts-ignore
                if (item.STAGE_TYPE !== "FINISHED") {
                    let weekIncrease = 0;

                    while (closestWeekFound === null) {
                        // @ts-ignore
                        const date = new Date(item.START_TIME * 1000);
                        const weekOfMatch = getWeekNumber(date);

                        if (weekOfToday + weekIncrease === weekOfMatch) {
                            closestWeekFound = weekOfToday + weekIncrease;
                            break;
                        } else if (weekOfToday + weekIncrease < weekOfMatch) {
                            weekIncrease++;
                        } else {
                            break;
                        }
                    }
                }
            }
        });

        if (closestWeekFound !== null) {
            filtered = itemsList.filter(item => {
                // @ts-ignore
                if (item.STAGE_TYPE !== "FINISHED") {
                    // @ts-ignore
                    const date = new Date(item.START_TIME * 1000);
                    const weekOfMatch = getWeekNumber(date);

                    return weekOfMatch === closestWeekFound;
                } else {
                    return true;
                }
            });
        } else {
            // @ts-ignore
            filtered = itemsList.filter(item => item.STAGE_TYPE === "FINISHED");
        }
        setFilteredItems(filtered);

    }, [itemsList]);

    return (
        <>
            <div
                className="vertical-scroll-container"
                ref={scrollViewRef}
                style={{userSelect: 'none', height: height, overflowY: 'auto'}}
            >
                <div>
                    {filteredItems.length > 0 && filteredItems.map((item, index) => (
                        <div key={index}>
                            <MatchSlot
                                // @ts-ignore
                                matchId={item.EVENT_ID} team1={item.HOME_PARTICIPANT_NAME_ONE} team2={item.AWAY_PARTICIPANT_NAME_ONE} team1ImgUrl={item.HOME_IMAGES} team2ImgUrl={item.AWAY_IMAGES} score1={item.HOME_SCORE_CURRENT} score2={item.AWAY_SCORE_CURRENT} matchTime={item.START_TIME} predictions={predictions} matchOfTheDay={matchOfTheDay}></MatchSlot>
                        </div>
                    ))}
                    {filteredItems.length === 0 &&
                        <div style={{
                            display:"grid",
                            justifyContent:"center",
                            alignContent:"center"
                        }}>
                            <img src={require("./Images/NoMatch.png")} alt={"NoMatchIcon"} style={{
                                marginLeft:46
                            }}></img>
                            <text className={"subInfo"}>No matches or predictions to show!</text>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default ScrollContainerVerticalForMatchSlots;
import React, {useEffect, useState} from 'react';
import MembershipSlot from "./MembershipSlot";
import ScrollContainerVerticalForMatchSlots from "./ScrollContainerVerticalForMatchSlots";
import {collection, getDocs} from "firebase/firestore";
import {
    auth,
    championsHistoryMatchData,
    eplHistoryMatchData,
    firestore,
    turkeyHistoryMatchData
} from "./config/firebase";
import {NavLink} from "react-router-dom";
import Loading from "./Loading";

let nick = "Guest";
let walletId = "";

const ProfilePage = () => {

    const users = collection(firestore,"users");
    const [predictionData, setPredictionData] = useState([] as any);
    const [name, setName] = useState<string>(nick);
    const [isCopied, setIsCopied] = useState(false);
    const [activeScroll1, setActiveScroll1] = useState(true);
    const [activeScroll2, setActiveScroll2] = useState(false);
    const [activeScroll3, setActiveScroll3] = useState(false);
    const [matches1, setMatches1] = useState([] as any);
    const [matches2, setMatches2] = useState([]as any);
    const [matches3, setMatches3] = useState([]as any);
    const [classname1, setClassname1] = useState('categoryItems active');
    const [classname2, setClassname2] = useState('categoryItems');
    const [classname3, setClassname3] = useState('categoryItems');
    const [isLoading, setIsLoading] = useState(true);
    const [scrollHeight, setScrollHeight] = useState(200);
    useEffect(() => {
        setScrollHeight(window.innerHeight-480)
    }, []);

    let [rePredictAmount, setRePredictAmount] = useState<number>(0);
    let [doublePoint, setDoublePoint] = useState<number>(0);

    const [league1ImgUrl, setLeague1ImgUrl] = useState('');
    const [league2ImgUrl, setLeague2ImgUrl] = useState('');
    const [league3ImgUrl, setLeague3ImgUrl] = useState('');

    //@ts-ignore
    const userId = auth.currentUser?.uid;

    const getMatchHistory = async () => {
        setIsLoading(true);
        try {
            const prediction = await getDocs(users);
            const simplified = prediction.docs.map((doc) => ({...doc.data(),id:doc.id}));
            const predicts = simplified.filter((item) => {
                for (let i = 0; i < simplified.length; i++) {
                    if (item.id === userId){
                        return item;
                    }
                }
            });
            //@ts-ignore
            setPredictionData(predicts[0].predictions)

            turkeyHistoryMatchData().then(data => {
                //setLeague1ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
                const matches = data.DATA[0].EVENTS as string[][];
                const filtered = matches.filter((item) => {
                    //@ts-ignore
                    for (const predict of Object.keys(predicts[0].predictions)) {
                        //@ts-ignore
                        if(predict === item.HOME_PARTICIPANT_NAME_ONE + item.AWAY_PARTICIPANT_NAME_ONE){
                            return predict;
                        }
                    }
                });
                if (filtered.length>0){
                    setMatches1(filtered)
                    setActiveScroll1(true)
                }
            })
            championsHistoryMatchData().then(data => {
                //setLeague2ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
                const matches = data.DATA[0].EVENTS as string[][];
                const filtered = matches.filter((item) => {
                    //@ts-ignore
                    for (const predict of Object.keys(predicts[0].predictions)) {
                        //@ts-ignore
                        if(predict === item.HOME_PARTICIPANT_NAME_ONE + item.AWAY_PARTICIPANT_NAME_ONE){
                            return predict;
                        }
                    }

                });
                if (filtered.length>0){
                    setMatches2(filtered)
                }
            })
            eplHistoryMatchData().then(data => {
                //setLeague3ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
                const matches = data.DATA[0].EVENTS as string[][];
                const filtered = matches.filter((item) => {
                    //@ts-ignore
                    for (const predict of Object.keys(predicts[0].predictions)) {
                        //@ts-ignore
                        if(predict === item.HOME_PARTICIPANT_NAME_ONE + item.AWAY_PARTICIPANT_NAME_ONE){
                            return predict;
                        }
                    }
                });
                if (filtered.length>0){
                    setMatches3(filtered)
                }
            })
        }
        catch (err){
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getMatchHistory()
    }, []);

    const getUserData = async () => {
        try {
            const userData = await getDocs(users);
            return userData.docs.map((doc) => ({...doc.data(), id: doc.id}));
        }
        catch (err){
            console.log(err)
        }
    }

    useEffect(() => {
        getUserData().then(data => {
            if (data !== undefined){
                for (let i = 0; i < data.length; i++) {
                    if (userId === data[i].id){
                        //@ts-ignore
                        if (data[i].nickname !== undefined)
                            //@ts-ignore
                            setName(data[i].nickname);
                        //@ts-ignore
                        if (data[i].repredict !== undefined)
                            //@ts-ignore
                            setRePredictAmount(data[i].repredict)
                        else
                            setRePredictAmount(0)
                        //@ts-ignore
                        if (data[i].doublePoint !== undefined)
                            //@ts-ignore
                            setDoublePoint(data[i].doublePoint)
                        else
                            setDoublePoint(0)
                    }
                }
            }
        })
    }, []);

    const handleActivate1 = () => {
        setActiveScroll1(true)
        setActiveScroll2(false)
        setActiveScroll3(false)
        setClassname1("categoryItems active")
        setClassname2("categoryItems")
        setClassname3("categoryItems")
    }
    const handleActivate2 = () => {
        setActiveScroll1(false)
        setActiveScroll2(true)
        setActiveScroll3(false)
        setClassname1("categoryItems")
        setClassname2("categoryItems active")
        setClassname3("categoryItems")
    }
    const handleActivate3 = () => {
        setActiveScroll1(false)
        setActiveScroll2(false)
        setActiveScroll3(true)
        setClassname1("categoryItems")
        setClassname2("categoryItems")
        setClassname3("categoryItems active")
    }

    const handleClickCopy = () => {
        navigator.clipboard.writeText(walletId)
            .then(() => {
                console.log('Text successfully copied to clipboard');
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000); // Reset after 2s
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    return (
        <div>
            {isLoading ? (<Loading />) : (<div className={"profileDetails"}>
                <div className="profile-pic-big"></div>
                <div className={"rows-container"}>
                    <div className={"profile-detail-style"}>
                        <text style={{
                            paddingTop: 4,
                            fontSize: "16px",
                            color: "white",
                        }}>{name}
                            <text className={"subInfo"}></text>
                        </text>
                        <div className={"profile-edit-verified"}>
                            <NavLink to={"/store"} className={"membershipBadge"} style={{
                                marginLeft:"auto",
                                textDecoration: "none",
                            }}>FREE</NavLink>
                        </div>
                    </div>
                    <div className={"wallet-address"}>
                        <text style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                            display: "flex",
                            color: "grey"
                        }}>{walletId} </text>
                        <button onClick={handleClickCopy} className={"wallet-copy"}></button>
                    </div>
                    <div style={{
                        display: "flex",
                        gap: 4
                    }}>
                        <h4 className={"badgeSlots"}>Joined League: 0</h4>
                        <h4 className={"badgeSlots"}>Leagues won: 0</h4>
                    </div>
                    <div style={{
                        display: "flex",
                        gap: 4
                    }}>
                        <h4 className={"badgeSlots"}>Reprediction: {rePredictAmount}</h4>
                        <h4 className={"badgeSlots"}>x2 points: {doublePoint}</h4>
                    </div>
                </div>
                <div style={{
                    gridArea: "second-row",
                    marginTop: 12,
                }}>
                    <h4 className={"headerText"}>Your Membership Plan</h4>
                    <h4 className={"subInfo"}>You have a free membership</h4>
                </div>
                <div style={{
                    gridArea: "third-row",
                    display:"flex",
                    justifyContent:"center"
                }}>
                    <MembershipSlot VIP={false}></MembershipSlot>
                </div>
                <div style={{
                    gridArea: "forth-row",
                    justifyContent:"center"
                }}>
                    <div style={{
                        gridArea: "second-row",
                        marginTop: 12
                    }}>
                        <h4 className={"headerText"}>Predict History</h4>
                        <h4 className={"subInfo"}>You can view the matches you predicted here.</h4>
                    </div>
                    <div>
                        <div className={"buttonContainer"}>
                            <button onClick={handleActivate1} className={classname1}>
                                <img src={require("./Images/tslIcon.png")} alt="team1Img" style={{
                                    width: 24,
                                    height: 24
                                }}></img>
                                TSL
                            </button>
                            <button onClick={handleActivate2} className={classname2}>
                                <img src={require("./Images/clIcon.png")} alt="team1Img" style={{
                                    width: 24,
                                    height: 24
                                }}></img> CL
                            </button>
                            <button onClick={handleActivate3} className={classname3}>
                                <img src={require("./Images/eplIcon.png")} alt="team1Img" style={{
                                    width: 24,
                                    height: 24
                                }}></img> EPL
                            </button>
                        </div>
                        <div>
                            <div>
                                {activeScroll1 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                        itemsList={matches1}
                                                                                        predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                                {activeScroll2 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                        itemsList={matches2}
                                                                                        predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                                {activeScroll3 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                                        itemsList={matches3}
                                                                                        predictions={predictionData} matchOfTheDay={null}></ScrollContainerVerticalForMatchSlots>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    );
};

export default ProfilePage;
import React, {useEffect, useState} from 'react';
import {onAuthStateChanged, signInAnonymously,User} from "firebase/auth";
import {auth, firestore} from "./config/firebase";
import {collection, doc, setDoc} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import Loading from "./Loading";

const UserLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, async (user: User | null) => {
            if (user) {
                const uid = user.uid;
                setIsLoading(false)
                navigate('/main');
            } else {
                signInAnonymously(auth)
                    .then( async () => {
                        const id:string = auth.currentUser?.uid as string;
                        const userPredictionDocRef = doc(collection(firestore, 'users'),id);
                        const idIndicator = id.substring(0,6);
                        await setDoc(userPredictionDocRef, { nickname: "Guest#"+ idIndicator, repredict: 0,doublePoint:0 }, { merge: true });
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        console.error('Error signing in anonymously', error);
                    });
            }

        });
    }, [navigate]);
    return (
        <div>
            {isLoading ? (<Loading />):<div>
            </div>}
        </div>
    );
};

export default UserLogin;
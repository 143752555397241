import React from 'react';

interface teams  {
    team1:string;
    team2:string;
    team1ImgUrl:string;
    team2ImgUrl:string;
    score1:string;
    score2: string;
    matchTime:number;
}

const MatchOfTheDay: React.FC<teams>= ({team1,team2,team1ImgUrl,team2ImgUrl, score1,score2,matchTime}) => {

    let date = new Date(matchTime * 1000);

    return (
        <div className={"mostPopularMatchContainer"}>
            <div className={"matchSlot mostPopularMatch"}>
                <div style={{
                    display: "grid",
                    alignItems: "center",
                    justifyItems: "center",
                    gap: "10px",
                }}>
                    <img src={team1ImgUrl} className={"circle-flag"} alt="team1Img" style={{
                        marginTop:30
                    }}></img>
                    <text style={{
                        color: "white",
                        fontSize: "18px"
                    }}>{team1}
                    </text>
                </div>
                <div>
                    <h2 style={{
                        color: "black",
                        fontSize: "12px",
                        borderRadius: "5px",
                        backgroundColor: "#FFCC00",
                        marginBottom: "30px",
                        padding: "3px"
                    }}>GOLD MATCH
                    </h2>
                    <h6 style={{
                        color: "white"
                    }}>V</h6>
                    <h2 className={"subInfo"}>{date ? date.toLocaleTimeString([], { day:"numeric",month:"numeric",year:"numeric",hour: '2-digit', minute: '2-digit',hour12: false}):""}</h2>
                </div>
                <div style={{
                    display: "grid",
                    alignItems: "center",
                    justifyItems: "center",
                    gap: "10px",
                }}>
                    <img src={team2ImgUrl} className={"circle-flag"} alt="team2Img" style={{
                        marginTop:30
                    }}></img>
                    <text style={{
                        color: "white",
                        fontSize: "18px"
                    }}>{team2}
                    </text>
                </div>
            </div>
        </div>
    );
};

export default MatchOfTheDay;
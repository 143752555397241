import React, {useEffect, useState} from 'react';
import ScrollContainerVerticalForMatchSlots from "./ScrollContainerVerticalForMatchSlots";
import ScrollContainerHorizontal from "./ScrollContainerHorizontal";
import {auth, championsMatchData, eplMatchData, firestore, turkeyMatchData} from "./config/firebase";
import {collection, getDocs} from "firebase/firestore"
import MatchOfTheDay from "./MatchOfTheDay";
import Loading from "./Loading";

type MatchData = {
    id: string;
    team1: string;
    team2: string;
    team1ImgUrl:string;
    team2ImgUrl:string;
    score1: number;
    score2: number;
    matchHour: string;
};

const MainPage:React.FC = () => {

    const matchOfTheDayRef = collection(firestore,"matchOfTheDay");
    const userD = collection(firestore,"users");

    const [matches1, setMatches1] = useState([]);
    const [matches2, setMatches2] = useState([]);
    const [matches3, setMatches3] = useState([]);
    const [userData, setUserData] = useState([] as any);
    const [matchOfTheDay, setMatchOfTheDay] = useState([] as any);
    const [matchesLive, setMatchesLive] = useState([] as any);
    const [scrollHeight, setScrollHeight] = useState(200);
    const [isLoading, setIsLoading] = useState(true);

    const [activeScroll1, setActiveScroll1] = useState(true);
    const [activeScroll2, setActiveScroll2] = useState(false);
    const [activeScroll3, setActiveScroll3] = useState(false);

    const [classname1, setClassname1] = useState('categoryItems active');
    const [classname2, setClassname2] = useState('categoryItems');
    const [classname3, setClassname3] = useState('categoryItems');

    const [league1ImgUrl, setLeague1ImgUrl] = useState('');
    const [league2ImgUrl, setLeague2ImgUrl] = useState('');
    const [league3ImgUrl, setLeague3ImgUrl] = useState('');

    //@ts-ignore
    const userId = auth.currentUser?.uid;

    const getPredictions = async () => {
        setIsLoading(true)
        try {
            const prediction = await getDocs(userD);
            const simplified = prediction.docs.map((doc) => ({...doc.data(),id:doc.id}));

            for (let i = 0; i < simplified.length; i++) {
                if (simplified[i].id === userId)
                    { // @ts-ignore
                        setUserData(simplified[i].predictions)
                    }
            }
        }
        catch (err){
            console.log(err)
        }
    };
    const getMatchOfTheDay = async () => {
        try {
            const matchData = await getDocs(matchOfTheDayRef);
            const simplified = matchData.docs.map((doc) => ({...doc.data(),id:doc.id}))[0] as MatchData;
            return simplified;
        }
        catch (err){
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        setScrollHeight(window.innerHeight-455)
    }, []);

    useEffect(() => {
        turkeyMatchData().then(data => {
            //setLeague1ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
            getMatchOfTheDay().then(match => {
                for (let i = 0; i < data.DATA[0].EVENTS.length; i++) {
                    // @ts-ignore
                    if (data.DATA[0].EVENTS[i].HOME_NAME.includes(match.team1) && data.DATA[0].EVENTS[i].AWAY_NAME.includes(match.team2)){
                        setMatchOfTheDay(data.DATA[0].EVENTS[i]);
                    }
                }
            });
            setMatches1(data.DATA[0].EVENTS)
        })
        championsMatchData().then(data => {
            //setLeague2ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
            getMatchOfTheDay().then(match => {
                for (let i = 0; i < data.DATA[0].EVENTS.length; i++) {
                    // @ts-ignore
                    if (data.DATA[0].EVENTS[i].HOME_NAME.includes(match.team1) && data.DATA[0].EVENTS[i].AWAY_NAME.includes(match.team2)){
                            setMatchOfTheDay(data.DATA[0].EVENTS[i]);
                    }
                }
            });
            setMatches2(data.DATA[0].EVENTS)
        })
        eplMatchData().then(data => {
            //setLeague3ImgUrl(data.DATA[0].TOURNAMENT_IMAGE);
            setMatches3(data.DATA[0].EVENTS)
            getMatchOfTheDay().then(match => {
                for (let i = 0; i < data.DATA[0].EVENTS.length; i++) {
                    // @ts-ignore
                    if (data.DATA[0].EVENTS[i].HOME_NAME.includes(match.team1) && data.DATA[0].EVENTS[i].AWAY_NAME.includes(match.team2)){
                        setMatchOfTheDay(data.DATA[0].EVENTS[i]);
                    }
                }
            });
            setMatchesLive(data.DATA[0].EVENTS.concat(matches1,matches2))
        })
        getPredictions();
    }, []);

    const handleActivate1 = () => {
        setActiveScroll1(true)
        setActiveScroll2(false);
        setActiveScroll3(false);
        setClassname1("categoryItems active")
        setClassname2("categoryItems")
        setClassname3("categoryItems")
    }
    const handleActivate2 = () => {
        setActiveScroll2(true)
        setActiveScroll1(false);
        setActiveScroll3(false);
        setClassname1("categoryItems")
        setClassname2("categoryItems active")
        setClassname3("categoryItems")
    }
    const handleActivate3 = () => {
        setClassname1("categoryItems")
        setClassname2("categoryItems")
        setClassname3("categoryItems active")
        setActiveScroll3(true)
        setActiveScroll1(false);
        setActiveScroll2(false);
    }

    return (
        <div>
            {isLoading ? (<Loading />): (
                <div className={"mainPageItemContainer"}>
                <div style={{
                    width: "95vw",
                    textAlign: "left"
                }}>
                    <h4 className={"headerText"}>Hello</h4>
                    <h4 className={"subInfo"}>Did you make your predictions today?</h4>
                </div>
                {matchOfTheDay ? (
                    <MatchOfTheDay
                        //@ts-ignore
                        team1={matchOfTheDay.HOME_PARTICIPANT_NAME_ONE} team2={matchOfTheDay.AWAY_PARTICIPANT_NAME_ONE} team1ImgUrl={matchOfTheDay.HOME_IMAGES} team2ImgUrl={matchOfTheDay.AWAY_IMAGES} score1={"0"}
                        score2={"0"} matchTime={matchOfTheDay.START_TIME}></MatchOfTheDay>
                ) : ""}
                <div style={{
                    width:"100vw",
                    display: "flex",
                    justifyContent: "start"
                }}>
                    <text className={"headerText"} style={{
                        color: "#00FF1A",
                        fontSize: 15,
                        marginLeft:12
                    }}>LIVE MATCHES</text>
                </div>
                {matchesLive.length === 0 &&
                    <div style={{
                        height: "9vh",
                        display: "flex"
                    }}>
                        <text className={"subInfo"} style={{
                            marginTop: 15
                        }}>No live matches now
                        </text>
                    </div>}
                {
                    matchesLive.length > 0 &&
                    <ScrollContainerHorizontal itemsList={matchesLive}></ScrollContainerHorizontal>}
                <div style={{
                    display: "flex",
                    gap: "21vw",
                }}>
                    <h2 style={{
                        color: "white",
                        alignSelf: "start",
                        fontSize: 15
                    }}>THIS WEEK'S MATCHES</h2>
                </div>
                    <div className={"buttonContainer"} style={{
                        marginTop: 0
                    }}>
                        <button onClick={handleActivate1} className={classname1}>
                            <img src={require("./Images/tslIcon.png")} alt="team1Img" style={{
                            width: 24,
                            height: 24
                        }}></img>
                            TSL
                        </button>
                        <button onClick={handleActivate2} className={classname2}>
                            <img src={require("./Images/clIcon.png")} alt="team2Img" style={{
                                width: 24,
                                height: 24
                            }}></img> CL
                        </button>
                        <button onClick={handleActivate3} className={classname3}>
                            <img src={require("./Images/eplIcon.png")} alt="team3Img" style={{
                                width: 24,
                                height: 24
                            }}></img> EPL
                        </button>
                    </div>
                    <div style={{
                        display: "flex",
                    }}>
                        {activeScroll1 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                              itemsList={matches1} predictions={userData} matchOfTheDay={matchOfTheDay}></ScrollContainerVerticalForMatchSlots>}
                        {activeScroll2 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                               itemsList={matches2} predictions={userData} matchOfTheDay={matchOfTheDay}></ScrollContainerVerticalForMatchSlots>}
                        {activeScroll3 && <ScrollContainerVerticalForMatchSlots height={scrollHeight}
                                                                               itemsList={matches3} predictions={userData} matchOfTheDay={matchOfTheDay}></ScrollContainerVerticalForMatchSlots>}
                </div>

            </div>)}
        </div>

    );
};

export default MainPage;